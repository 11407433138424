import { openApiId, openApiUuid } from "../../common/types";

export enum ContractSchedule {
  Always = "always",
  NightsAndWeekends = "nights-and-weekends",
}

export type SlotTarget =
  | { hallId: string; laneId?: never }
  | { laneId: string; hallId?: never };

export type SlotInput = SlotTarget & {
  platesAmount: number | null;
};

export type SlotWithLimit = SlotTarget & {
  platesAmount: number;
};

export type SlotWithHall = {
  hallId: string;
  laneId?: string | null;
  platesAmount: number | null;
};

export type ContractSlot = SlotInput & {
  contractId: number;
};

export type ContractSlotInput = {
  contractId: number;
} & SlotsRequest;

export type ContractSlotDb = ContractSlotInput & {
  id: number;
};

export type SlotTargetRequest =
  | { hallId: string; laneId?: null }
  | { hallId?: null; laneId: string };

export type SlotsRequest = { platesAmount: number | null } & SlotTargetRequest;

export type ContractRequest = {
  startDate: string;
  endDate?: string | null;
  companyId: number;
  slots: SlotsRequest[];
  schedule?: ContractSchedule;
};

export type ContractInput = Omit<
  ContractRequest,
  "slots" | "startDate" | "endDate"
> & {
  startDate: Date | string;
  endDate?: Date | string | null;
};

export type ContractDb = Omit<
  ContractInput,
  "startDate" | "endDate" | "schedule"
> & {
  startDate: Date;
  endDate: Date | null;
  schedule: ContractSchedule;
  id: number;
};

export type ContractDbWithSlots = ContractDb & {
  slots: {
    hallId: string;
    laneId?: string | null;
    name: string;
    platesAmount: number | null;
  }[];
};

/**
 * Contract as returned by the API.
 */
export type Contract = Omit<ContractRequest, "slots"> & {
  id: number;
  endDate: string | null;
  slots: (SlotWithHall & { name: string })[];
};

export const openApiContractSlotInput = {
  oneOf: [
    {
      type: "object",
      required: ["laneId", "platesAmount"],
      properties: {
        laneId: openApiUuid,
        platesAmount: {
          type: "integer",
          minimum: 0,
          nullable: true,
        },
      },
    },
    {
      type: "object",
      required: ["hallId", "platesAmount"],
      properties: {
        hallId: openApiUuid,
        platesAmount: {
          type: "integer",
          minimum: 0,
          nullable: true,
        },
      },
    },
  ],
};

export const openApiContractInput = {
  type: "object",
  required: ["startDate", "companyId", "slots"],
  additionalProperties: false,
  properties: {
    companyId: openApiId,
    startDate: {
      type: "string",
      format: "date-time",
    },
    endDate: {
      type: "string",
      format: "date-time",
      nullable: true,
    },
    schedule: {
      type: "string",
      enum: Object.values(ContractSchedule),
    },
    slots: {
      type: "array",
      minItems: 1,
      items: openApiContractSlotInput,
    },
  },
};

export const openApiContract = {
  type: "object",
  required: ["id", ...openApiContractInput.required],
  properties: {
    id: openApiId,
    ...openApiContractInput.properties,
  },
};
