import React, { ReactNode } from "react";
import { createGlobalStyle, ThemeProvider } from "styled-components";

interface ThemeProps {
  children: ReactNode;
}

const darkTheme = {
  stripeTheme: "night",
  colors: {
    alertFail: "#FF0000",
    alertSuccess: "#008000",
    background: "rgb(50, 55, 65)",
    backgroundTransparent: "rgb(50, 55, 65, 0)",
    buttonTextPrimary: "#fff",
    cardBg: "rgba(60, 70, 80, 0.5)",
    highlightLink: "rgba(255, 255, 255, 0.8)",
    inputBg: "rgba(60, 70, 80)",
    primary: "#494eff",
    secondary: "#181818",
    secondaryLink: "rgba(255, 255, 255, 0.4)",
    textHighlight: "#fff",
    textNegative: "#000",
    textPrimary: "rgba(255, 255, 255, 0.8)",
    textSecondary: "rgba(255, 255, 255, 0.5)",
  },
};

const lightTheme: typeof darkTheme = {
  stripeTheme: "stripe",
  colors: {
    alertFail: "#FF0000",
    alertSuccess: "#008000",
    background: "rgb(255, 255, 255)",
    backgroundTransparent: "rgb(255, 255, 255, 0)",
    buttonTextPrimary: "#fff",
    cardBg: "rgba(230, 230, 230, 0.5)",
    highlightLink: "rgba(0, 0, 0, 0.8)",
    inputBg: "rgba(230, 230, 230)",
    primary: "#494eff",
    secondary: "#fff",
    secondaryLink: "rgba(0, 0, 0, 0.4)",
    textHighlight: "#000",
    textNegative: "#fff",
    textPrimary: "rgba(0, 0, 0, 0.8)",
    textSecondary: "rgba(0, 0, 0, 0.5)",
  },
};
const Theme: React.FC<ThemeProps> = ({ children }) => {
  const theme =
    localStorage.getItem("light") === "true" ? lightTheme : darkTheme;

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle colors={theme.colors} />
      {children}
    </ThemeProvider>
  );
};

const camelToDashed = (str: string) =>
  str.replace(/([a-zA-Z])(?=[A-Z])/g, "$1-").toLowerCase();

const GlobalStyle = createGlobalStyle<{
  colors: typeof darkTheme.colors;
}>`
  :root {
    ${({ colors }) =>
      Object.entries(colors)
        .map(([k, v]) => `--${camelToDashed(k)}:${v};`)
        .join("\n")}
  }

  body {
    color: var(--text-primary);
    background-color: var(--background);
  }

  .Toastify__toast--default {
    background: var(--background);
  }
`;

export default Theme;
