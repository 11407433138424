import { i18n } from "@lingui/core";
import { useLingui } from "@lingui/react";
import { getLanguage, setLanguage } from "common/localStorage";
import { useEffect } from "react";
import styled from "styled-components";
import { DropdownMenu, DropdownMenuItem } from "./DropdownMenu";

const supportedLanguages = ["fi", "en"] as const;
export type SupportedLanguage = "fi" | "en";
const isSupportedLanguage = (str: unknown): str is SupportedLanguage =>
  typeof str === "string" &&
  (supportedLanguages as unknown as string[]).includes(str);

const defaultLanguage: SupportedLanguage =
  window.navigator.languages
    .map((locale) => new Intl.Locale(locale).language)
    .find((language): language is SupportedLanguage =>
      isSupportedLanguage(language)
    ) || supportedLanguages[1];

const activate = async (language: SupportedLanguage) => {
  const { messages } = await import(`../locales/${language}/messages`);
  setLanguage(language);
  i18n.load(language, messages);
  i18n.activate(language);
};

export const useActivate = () => {
  const savedLanguage = getLanguage();
  const language = isSupportedLanguage(savedLanguage)
    ? savedLanguage
    : defaultLanguage;
  useEffect(() => void activate(language), [language]);
};

export const LanguageSelection = ({ openUp }: { openUp?: boolean }) => {
  const { i18n } = useLingui();
  const languageByLocale: { [key: string]: string } = {
    fi: "fin",
    en: "eng",
  };
  const displayLanguage = languageByLocale[i18n.locale];
  return (
    <StyledDropdownMenu
      placeholder={displayLanguage}
      closeOnClick
      openUp={openUp}
    >
      {supportedLanguages.map((language) => (
        <StyledDropdownMenuItem
          onClick={() => {
            activate(language);
          }}
          key={language}
        >
          {languageByLocale[language]}
        </StyledDropdownMenuItem>
      ))}
    </StyledDropdownMenu>
  );
};

const StyledDropdownMenu = styled(DropdownMenu)<{
  openUp?: boolean;
}>`
  text-transform: uppercase;

  .DropdownMenu__Placeholder {
    font-size: var(--fs-smallish);
    background-color: transparent;
    height: 40px;
    vertical-align: middle;
  }

  .DropdownMenu__Content {
    ${({ openUp }) => (openUp ? "bottom: 40px; top: initial" : "top: 40px;")}
  }

  svg.icon {
    height: 12px;
    margin-top: -5px;
  }
`;
const StyledDropdownMenuItem = styled(DropdownMenuItem)`
  && {
    font-size: var(--fs-smallish);
  }
`;
