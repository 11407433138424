import { Trans } from "@lingui/macro";
import { VisitsResponse } from "common/models/visits/types";
import { formatDateTime } from "common/utils";
import { PaymentTicket } from "components/PaymentTicket";
import { StickyBottom } from "components/StickyBottom";
import styled from "styled-components";
import { formatDateDurationShort } from "../../../../backend/src/common/datesUtils";

type VisitsProps = {
  paymentId?: string;
  visits: VisitsResponse;
};

export const Visits = ({ paymentId, visits }: VisitsProps) => {
  return (
    <>
      <VisitsElement>
        {visits.length > 1 && (
          <Textbox>
            <TextInfo>
              <Trans>{visits.length} paid parkings</Trans>
            </TextInfo>
          </Textbox>
        )}
        <TicketList>
          <HorizontalGradientOverlay />
          <Tickets paymentId={paymentId} visits={visits} />
        </TicketList>
      </VisitsElement>
      <StickyBottom>
        <VerticalGradientOverlay />
      </StickyBottom>
    </>
  );
};

type TicketsProps = {
  paymentId?: string;
  visits: VisitsResponse;
};

const Tickets = ({ paymentId, visits }: TicketsProps) => {
  return (
    <>
      {visits.map((visit) => (
        <PaymentTicket
          key={visit.id}
          parking={visit.hallName}
          status={
            visit.exitDate ? (
              <Trans>Finished parking</Trans>
            ) : (
              <Trans>Ongoing parking</Trans>
            )
          }
          start={formatDateTime(visit.entranceDate)}
          duration={
            visit.entranceDate
              ? formatDateDurationShort(
                  new Date(visit.entranceDate),
                  visit.billingEndTime
                    ? new Date(visit.billingEndTime)
                    : new Date()
                )
              : ""
          }
          fee={
            paymentId
              ? visit.payments.find((p) => p.paymentId === paymentId)?.price
              : undefined
          }
        />
      ))}
    </>
  );
};

const VisitsElement = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;
`;

const TextInfo = styled.p`
  color: var(--text-secondary);
`;

const Textbox = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TicketList = styled.div`
  display: flex;
  gap: 20px;
  max-width: 100%;
  max-height: 100%;

  @media (orientation: landscape) {
    flex-direction: row;
    overflow-x: auto;
    padding-right: 20px;
  }

  @media (orientation: portrait) {
    flex-direction: column;
    overflow-y: auto;
    padding-bottom: 20px;
  }

  @media (max-width: 480px) {
    width: 100%;
  }
`;

const HorizontalGradientOverlay = styled.div`
  position: absolute;
  pointer-events: none;

  @media (orientation: landscape) {
    background: linear-gradient(
      to right,
      var(--background-transparent),
      var(--background) 100%
    );
    right: 0;
    width: 20px;
    top: 0;
    bottom: 0;
  }

  @media (orientation: portrait) {
    display: none;
  }
`;

const VerticalGradientOverlay = styled.div`
  position: absolute;
  pointer-events: none;

  @media (orientation: landscape) {
    display: none;
  }

  @media (orientation: portrait) {
    background: linear-gradient(
      to bottom,
      var(--background-transparent),
      var(--background) 100%
    );
    bottom: 0;
    height: 20px;
    left: 0;
    right: 0;
  }
`;
