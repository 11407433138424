import countries from "i18n-iso-countries";

export const countryCodes = Object.keys(countries.getAlpha3Codes());
export const countryCodesAlpha2 = Object.keys(countries.getAlpha2Codes());
export const paymentTerminalJwtSub = "paymentTerminal";
export const defaultTimeZone = "Europe/Helsinki";
export const defaultInvoiceFee = 390;
export const emptyEncryptedValue =
  '{ "encrypted": "", "iv": "", "tag": "", "sort": "" }';
export const dayInMinutes = 24 * 60;
